import React, { useState } from "react";
import MinimalSideViewModal from "../GeneralComponents/MinimalSideViewModal";
import { getTranslation } from "../../../helpers/common";

const SpeakerCardMinimalistic = ({ speaker, disableOnClick, layout, matchedEventTranslationConfig }) => {
    const [openModal, setOpenModal] = useState(false);

    const handleClick = () => {
        if (!disableOnClick) {
            setOpenModal(prev => !prev);
        }
    };

    return (
        <>
            <div className={` ${layout === 'compact' ? "speaker-card row m-0 py-3 pb-0 pb-sm-2 py-md-2 h-100 d-flex flex-row" : "speaker-card row m-0 py-3 pb-0 pb-sm-2 py-md-2 h-100 d-block d-md-flex"}`}>
                <div
                    className={` ${layout === 'compact' ? ' col-3 col-md-3 speaker-imagee-container mb-3 mb-md-0' : '  col-12 col-md-5 speaker-image-container mb-3 mb-md-0'}`}
                    onClick={handleClick}
                >
                    <img src={speaker.image_url} className="speaker-image mx-auto" alt="" />
                </div>
                <div lang="de" className={`${layout === 'compact' ? "col-9 col-md-9 speaker-hypen text-sm-start justify-content-start align-items-start ps-1 pe-1" : "col-12 col-md-7 speaker-hypen text-sm-start text-center"}`}>
                    <div onClick={handleClick}>
                        <p className={`${layout === 'compact' ? "modal-s-name mt-0 mb-sm-1 mb-0" : "name mt-0 mb-sm-1 mb-0 "}`}>{speaker.name}</p>
                        <p className={`${layout === 'compact' ? "modal-s-desc2 mb-sm-1 mb-0" : "desc2 mb-sm-1 mb-0"}`}>{speaker.designation}</p>
                        {layout !== 'compact' &&
                            <p className="org mt-0 mb-sm-1 mb-0">{speaker.organisation}</p>
                        }
                    </div>
                    {(speaker?.linkedin_url ||
                        speaker?.facebook_url ||
                        speaker?.twitter_url ||
                        speaker?.website_url) && (
                            <div className="d-flex gap-2 flex-row mt-3 justify-content-center justify-content-sm-start">
                                {speaker?.facebook_url && (
                                    <div className="facebook_wrapper common-cursor">
                                        <div onClick={() => window.open(speaker.facebook_url)}>
                                            <img
                                                className="facebook"
                                                src="/img/facebook01.svg"
                                                alt={"facebook"}
                                            />
                                        </div>
                                    </div>
                                )}
                                {speaker?.twitter_url && (
                                    <div className="twitter_wrapper common-cursor">
                                        <div onClick={() => window.open(speaker.twitter_url)}>
                                            <img
                                                className="twitter"
                                                src="/img/twitter-mini.svg"
                                                alt={"twitter"}
                                            />
                                        </div>
                                    </div>
                                )}
                                {speaker?.linkedin_url && (
                                    <div className="linkedIn_wrapper common-cursor">
                                        <div onClick={() => window.open(speaker.linkedin_url)}>
                                            <img
                                                className="linkedIn"
                                                src="/img/linkedin01.svg"
                                                alt={"Linked In"}
                                            />
                                        </div>
                                    </div>
                                )}
                                {speaker?.website_url && (
                                    <div className="twitter_wrapper common-cursor">
                                        <div onClick={() => window.open(speaker.website_url)}>
                                            <img
                                                className="website"
                                                src="/img/Website01.svg"
                                                alt={"website"}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                </div>
            </div>
            <MinimalSideViewModal isOpen={openModal} setIsOpen={setOpenModal} background="#F5F5F5">
                <div className="p-4">
                    <div className="text-end mb-3">
                        <button
                            onClick={() => setOpenModal(false)}
                            className="border-0"
                            style={{
                                background: "#F5F5F5",
                                color: "#525252"
                            }}
                        >
                            <i className="fa-solid fa-xmark" style={{ fontSize: "18px" }}></i>
                        </button>
                    </div>
                    <div className="d-flex mb-4 speaker-box">
                        <div className="organiser-image-cont-2 rounded-circle me-3">
                            <img
                                src={speaker.image_url}
                                style={{ width: "180px" }}
                                alt={`${speaker.name} poster image`}
                                className="image-size"
                            />
                        </div>
                        <div className="d-flex flex-column speaker-about ">
                            <p className="speaker-name">{speaker.name}</p>
                            <p className="speaker-designation">{speaker.designation}</p>
                            <p className="speaker-organisation">{speaker.organisation}</p>
                            {speaker.location && (
                                <p className="speaker-organisation gap-2 d-flex">
                                    <img src="/img/prolocation.svg" alt="" />
                                    {speaker.location}</p>
                            )}
                            <div className="d-flex flex-row">
                                {speaker.facebook_url && (
                                    <div
                                        className="me-2"
                                        onClick={() => window.open(speaker.facebook_url)}
                                    >
                                        <img src="/img/facebook01.svg" alt="" />
                                    </div>
                                )}
                                {speaker.twitter_url && (
                                    <div
                                        className="me-2"
                                        onClick={() => window.open(speaker.twitter_url)}
                                    >
                                        <img
                                            className="twitter-speaker"
                                            src="/img/twitter-mini.svg"
                                            alt=""
                                        />
                                    </div>
                                )}
                                {speaker.linkedin_url && (
                                    <div
                                        className="me-2"
                                        onClick={() => window.open(speaker.linkedin_url)}
                                    >
                                        <img src="/img/linkedin01.svg" alt="" />
                                    </div>
                                )}
                                {speaker.website_url && (
                                    <div
                                        className="me-2"
                                        onClick={() => window.open(speaker.website_url)}
                                    >
                                        <img src="/img/Website01.svg" alt="" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {speaker.about && (
                        <p
                            className="mb-0 desc"
                            dangerouslySetInnerHTML={{
                                __html: `${speaker.about?.replace(/(?:\r\n|\r|\n)/g, "") || ""}`
                            }}
                            onClick={(e) => e.stopPropagation()}
                        />
                    )}
                    {speaker.tags && speaker.tags.length > 0 && (
                        <div className="speaker-tags">
                            <p className="my-3 tag-header">{matchedEventTranslationConfig?.match ? getTranslation('Tags', matchedEventTranslationConfig?.translateTo) : "Tags "}</p>
                            <div className="d-flex flex-wrap my-3">
                                {speaker.tags.map((tag) => (
                                    <div key={tag.id} className="me-2 mb-2 tag-container">
                                        <span className="tag-name">#{tag.name}</span>
                                    </div>
                                ))}
                            </div>
                        </div >
                    )}
                </div >
            </MinimalSideViewModal >
            <style jsx>{`
			    .me-2, .common-cursor{
                    cursor: pointer !important;
				}
				.speaker-card {
					// cursor: pointer;
					background: #ffffff;
					border-radius: 5px;
					border: 0.8px solid #cacaca;
					text-align: left;
					z-index: 100;
				}
				.speaker-box {
					gap: 20px;
				}
				.speaker-about {
					gap: 8px;
				}
				.speaker-hypen {
					-webkit-hyphens: auto;
					-moz-hyphens: auto;
					-ms-hyphens: auto;
					hyphens: auto;
					word-break: break-word;
				}
				.speaker-name {
					font-family: "Manrope";
					font-weight: 700;
					font-size: 20px;
					color: #1c2520;
				}
				.speaker-designation {
					font-family: "Manrope";
					font-weight: 500;
					font-size: 13px;
				}
				.speaker-organisation {
					font-family: "Manrope";
					font-weight: 500;
					font-size: 13px;
				}
				.icon {
					max-width: 17px;
					margin-top: -25px;
					cursor: pointer;
					-webkit-filter: invert(100%); /* Safari/Chrome */
					filter: invert(100%);
				}
				.speaker-image {
					cursor: pointer;
					border-radius: 5px;
					object-fit: cover;
					width: 100%;
					border: .8px solid #cacaca;
				}
				.name {
					cursor: pointer;
					font-weight: 800;
					margin-top: 5px;
					font-size: 1rem;
					color: #141b25;
				}
				.desc2 {
					cursor: pointer;
					color: #141B25;
				}
				.linkedIn,
				.facebook,
				.twitter,
				.website {
					width: 19px;
					margin-top: -19px;
				}
				.twitter-speaker {
					width: 24px;
					height: 24px;
				}
				.desc {
					font-family: "Manrope" !important;
					font-weight: 400;
					font-size: 13px;
					line-height: 20px;
					color: #000000;
					overflow-wrap: break-word;
					z-index: 100;
				}
				.org {
					cursor: pointer;
					font-style: normal;
					font-weight: 500;
					font-size: 0.875rem;
					color: #141b25;
				}

				.organiser-image-cont-2{
					border-radius: 10px !important;
				}
				.image-size{
	        		border-radius: 10px !important;
                    border: .8px solid #cacaca;
                }
				.tag-header{
                  font-family: "Prompt";
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  color: #141B25;
                }
                .tag-container{
                  background-color: #FFFFFF;
                  height: 28px;
                  padding: 0px 12px 0px 12px;
                  border-radius: 4px;
                }
                .tag-name{
                  font-family: "Hind";
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 20px;
                  color: #212529;
                }
				.speaker-imagee-container img{
					height:55px;
					width:55px;
				}
				.modal-s-name {
					font-size: 14px;
					font-weight: 700;
					color: #141B25 !important; 
				}
				.modal-s-desc2{
					font-size: 11px;
					font-weight: 400;
					color: #141B25 !important; 
				}

				@media (min-width: 992px) and (max-width: 1368px) {
				}
				@media (max-width: 768px) {
					.image-size {
						width: 120px !important;
					}
				}
				@media (max-width: 600px) {
					.speaker-box {
						flex-direction: column;
						align-items: start !important;
					}
					.image-size {
						width: 120px !important;
					}
				}
				
				@media (max-width: 576px) {
					.speaker-hypen {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
					}
					.speaker-image{
						display: flex;
						margin: auto;
						width: 85%;
					}
					.name {
						font-size: 15px;
						font-weight: 700;
					}
					
					.desc2{
						font-size: 11px;
					}
					
					.speaker-about {
						gap: 5px;
					}
					.speaker-image-container img{
						height:90px;
						width:90px;
					}
					.speaker-imagee-container img{
						height:55px;
						width:55px;
					}
				}

			`}</style>
        </>
    );
};

export default SpeakerCardMinimalistic;